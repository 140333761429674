import moment from 'moment';
import 'slick-carousel';

jQuery( document ).ready(function($) {
    //Events
    // $.ajax( {
    //     url: 'https://rest.bandsintown.com/artists/Lathan Warlick/events?app_id=45PRESS_lathan',
    //     method: 'GET',
    //     dataType: 'json',
    //     error: () => {
    //     },
    //     success: data => {
    //         const events = $( '#events');
    //         let html = '';
    //         console.log( data ); 
    //         if ( data.length ) {
    //             for ( let event of data ) {
    //                 let country = event.venue.country
    //                 country = country.replace(" ", "-")
    //                 let region = event.venue.region?event.venue.region:event.venue.country
    //                 const location = event.venue.city + ', ' + region;
    //                 html += '<div class="event">';
    //                 html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
    //                 html += '<div class="event-location">' + location + '</div>';
    //                 html += '<div class="event-venue">' + event.venue.name + '</div>';
    //                 html += '<div class="event-links">';
    //                 if (event.offers[0]){
    //                     for ( let offer of event.offers ) {
    //                         html += '<a href="' + offer.url + '" target="_blank" class="btn"><span>' + offer.type + '</span></a>';
    //                     }
    //                 } else {
    //                     html += '<a href="' + event.url + '" target="_blank" class="btn"><span>tickets</span></a>';
    //                 }
    //                 html += '</div>';
    //                 html += '</div>';
    //             }
    //             html += '<a href="#" class="toggle btn">See All Dates</a></div>';
    //             events.html( html );
    //         } else {
    //             events.html( '<div class="event no-event"><span>No upcoming events.</span>' );
    //         }
    //         $('.toggle').on('click', function(e){
    //             e.preventDefault();
    //             $('#events').addClass('seeall') 
    //         })
    //     },
    // } );

    

    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href');
    
        e.preventDefault();
    
        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    $('.hero-items').slick({
        infinite: true,
    });

    $('.music-items').slick({
        infinite: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<button class="arrow arrow-next"><i class="fas fa-angle-right"></i></button>',
        prevArrow: '<button class="arrow arrow-prev"><i class="fas fa-angle-left"></i></button>',
        responsive:[
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                }
            },
            
        ]
    });

    $('.video-items').slick({
        infinite: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<button class="arrow arrow-next"><i class="fas fa-angle-right"></i></button>',
        prevArrow: '<button class="arrow arrow-prev"><i class="fas fa-angle-left"></i></button>',
        responsive:[
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                }
            },
            
        ]
    });

    
	$('.newsletter').on('submit', function(e) {
		e.preventDefault();
		const DATA = $(this).serialize();
		$.ajax({
			type: 'POST',
			url: $(this).attr('action'),
			dataType: 'json',
			data: DATA,
			xhrFields: {
				withCredentials: false,
			},
			success: function (data) {
				$('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
			},
			error: function (err) {
				console.log(err);
			}
		});
	});
});